import axios from '@/libs/axios'

class DataService {
    getAll(page) {
        return axios.get("/products?page=" + page);
    }
    getCategory() {
        return axios.get('/lkbCategories')
    }
    getCompany() {
        return axios.get('/lkbCompany')
    }
    getCompanyType() {
        return axios.get('/companyType')
    }
    getbrandLkb() {
         return axios.get('/brandLkb');
    }
    getMachine(){
        return axios.get('machineLkb')
    }
    addMachine(data){
        return axios.post('/addMachineToProduct', data);
    }
    get(id) {
        return axios.get(`/products/${id}`);
    }
    create(data) {
        return axios.post("/products", data);
    }
    update(id, data) {
        return axios.post(`/products/${id}`, data);
    }
    delete(id) {
        return axios.delete(`/products/${id}`);
    }
    deleteAll() {
        return axios.delete(`/products`);
    }
    findByTitle(title) {
        return axios.get(`/products?title=${title}`);
    }
    changeStatus(id) {
        return axios.get(`/changeProductsStatus/${id}`)
    }
    changeOutStock(id){
        return axios.get(`/changeOutStock/${id}`)
    }
    getFuelType(){
        return axios.get(`/fuelsTypes`)
    }
}
export default new DataService();