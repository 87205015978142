<template>
    <b-card>
        <validation-observer ref="loginValidation"
        >
            <b-form
            class="p-2"
            @submit.prevent=""
            @reset.prevent
            >
                <b-row>
                    <!-- company Name -->
                    <b-col md="6" xl="4" class="mb-1">
                        <b-form-group
                        label="Company Name"
                        label-for="english-name"
                        >
                        <b-form-input
                            v-if="product.company"
                            id="company-name"
                            v-model="product.company.name"
                            autofocus
                            disabled
                            trim
                        />
                        </b-form-group>
                    </b-col>

                    <!-- Product Name -->
                    <b-col md="6" xl="4" class="mb-1">
                      <b-form-group
                      label="Product Name"
                      label-for="arabic-name"
                      >
                        <b-form-input
                            id="arabic-name"
                            v-model="product.name"
                            autofocus
                            trim
                            disabled
                        />
                      </b-form-group>
                    </b-col>
                </b-row>
                

            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"   
            >
          <!-- Value -->
          <b-col md="4">
          <!--  value -->
            <validation-provider
              #default="validationContext"
              name="Value"
              rules="required" 
              >
              <b-form-group
              label-for="value"
              >
                  <b-input-group
                      class="input-group-merge"
                  >
                  <v-select
                      id="value"
                      v-model="item.machine_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="machines"
                      :reduce="types => types.id"
                      :state="getValidationState(validationContext)"
                      select
                      style="width: 100%;"
                      placeholder="Value"
                  />

                  </b-input-group>

              <b-form-invalid-feedback class="d-block" v-if="validationContext.errors[0]">
                  {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.product_options_value_id">{{ allerrors.product_opions_value_id[0] }}</span>
              </b-form-group>
          </validation-provider>
          </b-col>


          <!-- Year -->
          <b-col md="2" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  name="year"
                  rules=""
              >
                  <b-form-group
                  label-for="Year"
                  >
                      <b-input-group
                          class="input-group-merge"
                      >
                      <b-form-input
                          id="year"
                          v-model="item.year"
                          autofocus
                          trim
                          type="number"
                          placeholder="Year"
                          :state="getValidationState(validationContext)"
                        />
                      </b-input-group>

                  <b-form-invalid-feedback class="d-block" v-if="validationContext.errors[0]">
                      {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.price">{{ allerrors.price[0] }}</span>
                  </b-form-group>
              </validation-provider>
          </b-col>
          
          
          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            style="margin-top: 0rem !important; margin-bottom: 0rem; height: 45px"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
          
        </b-row>


        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New Value</span>
        </b-button>


                
            </b-form>

        </validation-observer>

        <!-- <product-option /> -->


        <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    @click="validationForm"
                    v-if="!loading"
                    >
                    Add
                    </b-button>
                    <!-- spinner -->
                    <b-button
                    v-if="loading"
                    variant="primary"
                    disabled
                    class="mr-1"
                    >
                    <b-spinner small />
                    Loading...
                    </b-button>
                    <!-- end spinner -->
                </div>

</b-card>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BRow, BCol, BFormInvalidFeedback, BButton, BFormFile, BCard, BCardText, BMedia, BAvatar, BSpinner, BFormTextarea, BInputGroupAppend, BInputGroup,BFormTags,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email, integer } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import DataServices from './data/services'
import { avatarText } from '@core/utils/filter'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    vSelect,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormTags,

    ValidationProvider,
    ValidationObserver,
    ToastificationContent,

    // AddCompany,
    // AddUnit,
    // ProductOption,
    // AddMedicalRep

  },
  directives: {
    Ripple,
  },
  data() {
    return {
        required,
        alphaNum,
        email,
        integer,
        name: '',
        name_ar: '',
        slug: '',
        scientific: '',
        scientific_ar: '',
        description: '',
        description_ar: '',
        tag: [],
        tag_ar:[],
        unit_id:'',
        low_limit: '',
        company_id:'',
        headerType: 'Add',
        loading: false,
        brands: [],
        product:'',
        machines: [],
        medicalReps: [],
        medical_rep_id: '',
        allerrors: [],
        optionsValues: [],
        isAddNewCompanySidebarActive: false,
        isAddNewUnitSidebarActive: false,
        isAddNewMedicalRepsActive: false,
        imageUrl: '',
        products_image: '',
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.sendData()
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    getProduct(){
        this.isLoading = true;
        DataServices.get(this.$route.params.id)
        .then(response => {
          this.isLoading = false
          this.product = response.data.data

          console.log(this.$store.state.admin.productOption)
          this.$store.state.admin.productOption = []
            Object.entries(response.data.data.products_filter).forEach(([key, value]) => {
              this.$store.commit('admin/AddProductOption', value )
              });

        })
    },

    sendData(){
      this.loading = true;
        DataServices.addMachine(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$router.push('/products')  
          }
          else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },



    prepareData() {
      return {
        product_id: this.$route.params.id,
        machines : this.$store.state.admin.productOption
      }
        
        // Object.entries(this.$store.state.admin.productOption).forEach(([key, value]) => {
        //     data.append(`productOption[${key}][price]`, value.price)
        //     data.append(`productOption[${key}][unit_id]`, value.unit_id)
        //     data.append(`productOption[${key}][product_options_value_id]`, value.product_options_value_id)
        // });

    },

    repeateAgain() {
      this.$store.commit('admin/AddProductOption', {  machine_id: '', year: ''} )
    },
    removeItem(index) {
      this.$store.commit('admin/RemoveProductOption', index)
      // this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    getMachine(){
      DataServices.getMachine()
      .then(response => {
        this.machines = response.data.data
      })
    },
  },
  mounted() {
    this.getProduct()
    this.getMachine()
  },

  computed: {
    items () {
      return this.$store.getters['admin/GetProductOption']
    }
  },

  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText
    }
  },
}
</script>



<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
